<template>
  <div class="indemnite-kilometrique-update-page page-layout">
    <PageHeader
      title="Modifier indemnite kilometrique"
      :has-back="true"
      @back="goBack"
    />

    <md-card class="meep-form">
      <md-card-content>
        <div class="meep-form-column meep-form-single-column">
          <!--type de vehicule-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('type_vehicule') }">
              <label>Type de vehicule *</label>
              <md-select v-model="form.type_vehicule">
                <md-option value="auto">Auto</md-option>
                <md-option value="moto">Moto</md-option>
                <md-option value="cyclo">Cyclo</md-option>
              </md-select>
              <span class="md-error" v-show="errors.has('type_vehicule')">{{
                errors.first("type_vehicule")
              }}</span>
            </md-field>
          </div>

          <!--montant min-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('cv_min') }">
              <label>CV minimum</label>
              <md-input
                v-model="form.cv_min"
                v-validate="'required|numeric'"
                name="cv_min"
                type="cvt_min"
              />
              <span class="md-error" v-show="errors.has('cv_min')">{{
                errors.first("cv_min")
              }}</span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('cv_max') }">
              <label>CV maximum</label>
              <md-input
                v-model="form.cv_max"
                v-validate="'numeric'"
                name="cv_max"
                type="cv_max"
              />
              <span class="md-error" v-show="errors.has('cv_max')">{{
                errors.first("cv_max")
              }}</span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('km_min') }">
              <label>Km min</label>
              <md-input
                v-model="form.km_min"
                v-validate="'required|decimal'"
                name="km_min"
                type="km_min"
              />
              <span class="md-error" v-show="errors.has('km_min')">{{
                errors.first("km_min")
              }}</span>
            </md-field>
          </div>

          <!--km max-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('km_max') }">
              <label>Km max</label>
              <md-input
                v-model="form.km_max"
                v-validate="'decimal'"
                name="km_max"
                type="km_max"
              />
              <span class="md-error" v-show="errors.has('km_max')">{{
                errors.first("km_max")
              }}</span>
            </md-field>
          </div>

          <!--coef-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('coef') }">
              <label>Coef</label>
              <md-input
                v-model="form.coef"
                v-validate="'required|decimal'"
                name="coef"
                type="coef"
              />
              <span class="md-error" v-show="errors.has('coef')">{{
                errors.first("coef")
              }}</span>
            </md-field>
          </div>

          <!--montant additionnel-->
          <div class="meep-input">
            <md-field
              :class="{ 'md-invalid': errors.has('montant_additionnel') }"
            >
              <label>Montant additionnel</label>

              <md-input
                v-model="form.montant_additionnel"
                v-validate="'required|decimal'"
                name="montant_additionnel"
                type="montant_additionnel"
              />

              <span class="md-error" v-show="errors.has('montant_additionnel')">
                {{ errors.first("montant_additionnel") }}
              </span>
            </md-field>
          </div>

          <div class="md-layout md-alignment-center-right">
            <md-button
              class="md-raised md-primary"
              @click.native="openConfirmDeleteModal"
            >
              Supprimer cette indemnité kilometrique
            </md-button>

            <md-button class="md-raised md-primary" @click.native="updateKilo">
              Valider
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <confirm-action-modal
      v-if="isConfirmDeleteModalOpen"
      :text="confirmDeleteModalText"
      :objectToActUpon="form"
      @close="closeConfirmDeleteModal"
      @confirm="removeKilo"
    />
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import confirmActionModal from "../../../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";

export default {
  data() {
    return {
      isDebug: false,
      form: {
        type_vehicule: "",
        cv_min: "",
        cv_max: "",
        km_min: "",
        km_max: "",
        coef: "",
        montant_additionnel: "",
      },
      isConfirmDeleteModalOpen: false,
      confirmDeleteModalText: {
        header: "Suppression d'une indemnité kilometrique",
        body() {
          return "Vous êtes sur le point de supprimer l'indemnité kilometrique";
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      },
    };
  },
  components: {
    PageHeader,
    "confirm-action-modal": confirmActionModal,
  },

  beforeRouteEnter(to, from, next) {
    calculatorsModel
      .getKilo(to.params.id)
      .then(formData => {
        next(vm => {
          vm.form = formData;
          if (vm.isDebug) {
            console.log("indemniteKilometrique with id" + to.params.id + ":");
            console.log(vm.form);
          }
        });
      })
      .catch(() => {
        next(from.path);
      });
  },

  methods: {
    goBack() {
      window.history.back();
    },
    updateKilo() {
      this.$validator.validateAll().then(result => {
        if (result) {
          calculatorsModel
            .updateKilo(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: "L'indemnite kilometrique a bien été modifié",
              });
              this.$router.push(
                "/admin/calculators/indemnite-kilometrique/list"
              );
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
    removeKilo() {
      calculatorsModel
        .removeKilo(this.form.id)
        .then(() => {
          this.$toasted.global.AppSucces({
            message: "L'indemnite kilometrique  a suprimé",
          });
          this.$router.push("/admin/calculators/indemnite-kilometrique/list");
        })
        .catch(err => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },
    openConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.indemnite-kilometrique-update-page {
  .debug-log {
    max-height: 360px;
    overflow-y: scroll;
  }

  .md-card {
    margin: 32px auto;
  }
}
</style>
